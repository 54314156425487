import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingContent: {
        id: 'loader.loading-content',
        defaultMessage: 'Hang on, the page is loading.'
    },
    loadingContentCompleted: {
        id: 'loader.loading-content-completed',
        defaultMessage: 'The page is now loaded.'
    }
});
