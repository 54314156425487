/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { AuthenticationServiceFactory } from 'gw-portals-auth-js';
// importing LocaleService
import { LocaleService } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
// importing UtilService
import UtilService from '../../services/UtilService';

let inactivityTimerId;
let logoutConfirmationTimerId;

export default ({
    oAuthConfig,
    logoutWarningCallback,
    inactivityIntervalMins = 5,
    logoutConfirmationIntervalMins = 1
}) => {
    const authenticationService = AuthenticationServiceFactory(oAuthConfig);
    const auth = useAuthentication();
    const pageName = window?.location?.pathname?.split('/')[2];

    function initializeInActivityTimer() {
        const inactivityInterval = inactivityIntervalMins * 1000 * 60;
        // initial timer to check for inactivity
        inactivityTimerId = window.setTimeout(
            () => {
                const logoutConfirmationInterval = logoutConfirmationIntervalMins * 1000 * 60;

                // second timer to show warning message for a period of time
                logoutConfirmationTimerId = window.setTimeout(() => {
                    // applying google analytics tag
                    auth.logout().then(() => {
                        UtilService.deleteAllCookies();
                        if (['account-document-upload', 'account-send-message', 'account-mail', 'account-helpful-link', 'account-faq', 'unified-landing-screen', 'unnumbered-document-upload'].indexOf(pageName) >= 0) {
                            const eventCategory = UtilService.getlogoutInvokedEventCategory(pageName);
                            UtilService.publishGA('Error', eventCategory, LocaleService.getStoredLanguage());
                        } else {
                            UtilService.publishGA('Exit Page View', 'ExitPage_TimeOut', LocaleService.getStoredLanguage());
                        }
                        sessionStorage.clear();
                        window.location.href = UtilService.unifiedLogoutSessionExpired(LocaleService.getStoredLanguage());
                    });
                }, logoutConfirmationInterval);
                // eslint-disable-next-line no-use-before-define
                logoutWarningCallback(resetInactivityTimer, logoutConfirmationInterval);
            },
            inactivityInterval
        );
    }

    function deactivateTimers() {
        if (inactivityTimerId) {
            window.clearTimeout(inactivityTimerId);
            inactivityTimerId = null;
        }
        if (logoutConfirmationTimerId) {
            window.clearTimeout(logoutConfirmationTimerId);
            logoutConfirmationTimerId = null;
        }
    }

    function resetInactivityTimer() {
        deactivateTimers();
        initializeInActivityTimer();
    }

    authenticationService.onLoginStateChange((authData) => {
        const authenticatedFlag = authData.isLoggedIn;
        if (authenticatedFlag) {
            // kick off a timer when user logs in
            resetInactivityTimer();
        } else {
            // if user has logged out then deactivate the timers
            deactivateTimers();
        }
    });

    return {
        // this method is called by the transport service,
        // called every time the user makes an XHR call to the backend -
        resetInactivityTimer: resetInactivityTimer
    };
};
