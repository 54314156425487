/* eslint-disable no-unused-expressions */
import { validateMetadata, renderContentFromMetadata } from '@jutro/uiconfig';
import React, { useEffect, useContext, useCallback } from 'react';
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { Link } from '@jutro/components';
import metadata from './footer.metadata.json5';
import styles from './footer.module.scss';
import message from './footer.messages';
import UtilService from '../../services/UtilService';


export default function Footer() {
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        validateMetadata(metadata);
    }, []);

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const getLink = useCallback(
        (key) => {
            const href = UtilService.footerLink(key, LocaleService.getCurrentLanguage());
            return (
                <Link target="_blank" href={href} title="Opens a new tab" className={styles.linkContainer}>
                    {translator(message[key])}
                </Link>
            );
        },
        [translator]
    );

    /*
     * @param {string} key
     * @returns href
     * To get the socialmedia link based on key and active language
     */
    const getSocialLink = useCallback(
        (key) => {
            const href = UtilService.footerLink(key, LocaleService.getCurrentLanguage());
            return href;
        },
        []
    );

    useEffect(() => {
        document.getElementById('contactNumberLabel')?.setAttribute('href', 'tel:+1-800-387-0750');
    });

    /*
     To translate static text based on active language
     */
    const getContent = useCallback(
        (key) => {
            switch (key) {
                case 'workplaceAccident':
                    return translator(message.workplaceAccidents);
                case 'callUs':
                    return translator(message.callUs);
                case 'ContactUsNumber':
                    return translator(message.ContactUsNumber);
                case 'contactNumber':
                    return translator(message.contactNumber);
                case 'footerCopyright':
                    return translator(message.footerCopyright);
                default: translator(message.footerCopyright);
            }
            return null;
        },
        [translator]
    );
    useEffect(() => {
        document.getElementById('callUsNumberLink')?.setAttribute('href', 'tel:+1-800-387-0750');
    });

    /*
     *Component level overrides
     */
    const overrides = {
        healthAndSafetyStatisticsLink: {
            content: getLink('healthAndSafetyStatistics')
        },
        healthAndSafetyIndexLink: {
            content: getLink('healthAndSafetyIndex')
        },
        careersLink: {
            content: getLink('careers')
        },
        termsOfUseLink: {
            content: getLink('termsOfUse')
        },
        contactUsLink: {
            content: getLink('contactUs')
        },
        landAcknowledgementLink: {
            content: getLink('landAcknowledgement')
        },
        dayOfMourningLink: {
            content: getLink('dayOfMourning')
        },
        openDataLink: {
            content: getLink('openData')
        },
        accessibilityLink: {
            content: getLink('accessibility')
        },
        privacyLink: {
            content: getLink('privacy')
        },
        fairPracticesCommissionLink: {
            content: getLink('fairPracticesCommission')
        },
        otherLanguagesLink: {
            content: getLink('otherLanguages')
        },
        workplaceAccidentsLabel: {
            content: getContent('workplaceAccident')
        },
        callUsLabel: {
            content: getContent('callUs')
        },
        contactUsLabel: {
            content: getContent('ContactUsNumber')
        },
        callUsNumberLink: {
            content: getContent('callUsNumber'),
            title: translator(message.phoneNumberLinkTitle)
        },
        contactNumberLabel: {
            content: getContent('contactNumber'),
            title: translator(message.phoneNumberLinkTitle)
        },
        footerCopyrightLabel: {
            content: getContent('footerCopyright')
        },
        linkedinLink: {
            href: getSocialLink('linkedin'),
        },
        youtubeLink: {
            href: getSocialLink('youtube'),
        },
        twitterLink: {
            href: getSocialLink('twitter'),
        },
        instagramLink: {
            href: getSocialLink('instagram'),
        },
    };

    /*
     * Component level resolvers
     */
    const resolvers = {
        resolveClassNameMap: styles,
    };

    return renderContentFromMetadata(metadata.footer, overrides, resolvers);
}
