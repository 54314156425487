import { defineMessages } from 'react-intl';

export default defineMessages({
    healthAndSafetyStatistics: {
        id: 'footer.healthAndSafetyStatisticsLink',
        defaultMessage: 'Health and safety statistics',
    },
    healthAndSafetyIndex: {
        id: 'footer.healthAndSafetyIndexLink',
        defaultMessage: 'Health and Safety Index',
    },
    careers: {
        id: 'footer.careersLink',
        defaultMessage: 'Careers',
    },
    termsOfUse: {
        id: 'footer.termsOfUseLink',
        defaultMessage: 'Terms of use',
    },
    contactUs: {
        id: 'footer.contactUsLink',
        defaultMessage: 'Contact us',
    },
    landAcknowledgement: {
        id: 'footer.landAcknowledgementLink',
        defaultMessage: 'Land acknowledgement',
    },
    dayOfMourning: {
        id: 'footer.dayOfMourningLink',
        defaultMessage: 'Day of Mourning',
    },
    openData: {
        id: 'footer.openDataLink',
        defaultMessage: 'Open Data',
    },
    accessibility: {
        id: 'footer.accessibilityLink',
        defaultMessage: 'Accessibility',
    },
    privacy: {
        id: 'footer.privacyLink',
        defaultMessage: 'Privacy',
    },
    fairPracticesCommission: {
        id: 'footer.fairPracticesCommissionLink',
        defaultMessage: 'Fair Practices Commission',
    },
    otherLanguages: {
        id: 'footer.otherLanguagesLink',
        defaultMessage: 'Other languages',
    },
    workplaceAccidents: {
        id: 'footer.workplaceAccidentsLabel',
        defaultMessage: 'Fatal or catastrophic workplace injuries',
    },
    callUs: {
        id: 'footer.callUsLabel',
        defaultMessage: 'Report online or call us at 1-800-387-0750',
    },
    ContactUsNumber: {
        id: 'footer.contactUsLabel',
        defaultMessage: 'Contact us',
    },
    contactNumber: {
        id: 'footer.contactNumberLink',
        defaultMessage: '1-800-387-0750',
    },
    callUsNumber: {
        id: 'footer.callUsLinkNumber',
        defaultMessage: '1-800-387-0750',
    },
    footerCopyright: {
        id: 'footer.CopyrightLabel',
        defaultMessage: '© 2023, Workplace Safety and Insurance Board',
    }
});
