export default {
    name: 'customTheme',
    prefix: 'customTheme',
    rootStyle: {
        '--GW-COLOR-BACKGROUND-2':'#fff',
        '--GW-COLOR-BRAND-1': '#0076BF',
        '--GW-COLOR-BRAND-1-GRADIENT': '#0076BF',
        '--GW-FONT-FAMILY': 'Muli, Arial, sans-serif',
        '--GW-FONT-WEIGHT-REGULAR': '400',
        '--GW-FONT-SIZE': '16px',
        '--GW-COLOR-TEXT-1': '#333333',
        '--GW-LINE-HEIGHT': '1.2',
        '--data-table-header-background-custom': '#003359',
        '--data-table-header-text-custom': '#fff',
        '--data-table-chevron-not-sorted-color-custom': '#fff',
        '--blue-link-color': '#0076BF',
        '--generic-select-option-color': '#333333'
    },
    componentStyles: {
        Button: {
            button: {
                '--button-hover-color': '#fff',
                '--button-hover-bg-color': '#0076BF',
                '--button-hover-bg-image': '#0076BF',
                '--button-hover-border-color': '#0076BF',
                '--button-active-color': '#fff',
                '--button-active-bg-color': '#0076BF',
                '--button-active-bg-image': '#0076BF',
                '--button-active-border-color': '#0076BF',
                '--button-font-size': '16px',
                '--button-font-weight': 'normal',
                'border-radius': '16px'
            }

        },
        DropdownSelectField: {
            DropdownSelect: {
                '--GW-FONT-SIZE': '14px',
                '--generic-select-option-color': 'var(--GW-COLOR-TEXT-1)',
                '--generic-select-option-font-size': 'var(--GW-FONT-SIZE)',
                '--generic-select-option-hover-bg-color': 'var(--GW-COLOR-HIGHLIGHT-1-HOVER)',
                '--generic-select-option-selected-bg-color': 'var(--GW-COLOR-HIGHLIGHT-1)',
                '--generic-select-option-line-height': 'calc(var(--generic-select-option-font-size) * var(--GW-FONT-SIZE))',
                '--generic-select-option-padding-x': 'var(--GW-SPACING)',
                '--generic-select-option-padding-y': 'var(--GW-SPACING-XS)'
            }
        }

    }
};