import { defineMessages } from 'react-intl';

export default defineMessages({
    businessHome: {
        id: 'header.Business Home',
        defaultMessage: 'Business Home'
    },
    unifiedLandingScreen: {
        id: 'header.Unified Landing Screen',
        defaultMessage: 'Submit documents, send messages and view claims'
    },
    myProfile: {
        id: 'header.My Profile',
        defaultMessage: 'My Profile'
    },
    logout: {
        id: 'header.logout',
        defaultMessage: 'Logout'
    },
    onlineServices: {
        id: 'header.Online Services',
        defaultMessage: 'Online services'
    },
    french: {
        id: 'header.French',
        defaultMessage: 'Français'
    },
    menu: {
        id: 'header.modal.Menu',
        defaultMessage: 'Menu'
    },
    iAm: {
        id: 'header.I am Label',
        defaultMessage: 'I am : '
    },
    aBusiness: {
        id: 'header.iAmDropdown.input.Business',
        defaultMessage: 'a business'
    },
    injuredOrIll: {
        id: 'header.iAmDropdown.input.Injured or Ill',
        defaultMessage: 'Injured or Ill'
    },
    username: {
        id: 'header.username',
        defaultMessage: 'hello, '
    },
    exit: {
        id: 'header.exit',
        defaultMessage: 'Exit'
    },
    claimInformation: {
        id: 'header.claimInfo',
        defaultMessage: 'Claim information',
    },
    documents: {
        id: 'header.documents',
        defaultMessage: 'Documents',
    },
    messages: {
        id: 'header.messages',
        defaultMessage: 'Messages',
    },
    resources: {
        id: 'header.resources',
        defaultMessage: 'Resources',
    },
    claimOverview: {
        id: 'header.claimOverview',
        defaultMessage: 'Claim overview',
    },
    benefitPayment: {
        id: 'header.benefitPayment',
        defaultMessage: 'Benefit payments',
    },
    healthCareBenefits: {
        id: 'header.healthCareBenefits',
        defaultMessage: 'Health care benefits',
    },
    appeals: {
        id: 'header.appeals',
        defaultMessage: 'Appeals',
    },
    claimDocuments: {
        id: 'header.claimDocuments',
        defaultMessage: 'Claim documents',
    },
    sendUsMessage: {
        id: 'header.sendUsMessage',
        defaultMessage: 'Send us a message',
    },
    mail: {
        id: 'header.mail',
        defaultMessage: 'Mail',
    },
    helpfulLink: {
        id: 'header.helpfulLink',
        defaultMessage: 'Helpful links',
    },
    faq: {
        id: 'header.faq',
        defaultMessage: 'Frequently asked questions',
    },
    accountDocuments: {
        id: 'header.accountDocuments',
        defaultMessage: 'Account Documents',
    },
    sendUsMessageForAccount: {
        id: 'header.sendUsMessageForAccount',
        defaultMessage: 'Account Documents',
    },
    accountMailHome: {
        id: 'header.accountMailHome',
        defaultMessage: 'Inbox',
    },
    accountFaq: {
        id: 'header.accountFaq',
        defaultMessage: 'Frequently asked questions',
    },
    accountHelpfulLinks: {
        id: 'header.accountHelpfulLinks',
        defaultMessage: 'Helpful links',
    },
    reports: {
        id: 'header.reports',
        defaultMessage: 'Reports'
    },
    submitNewReport: {
        id: 'header.submitNewReport',
        defaultMessage: 'Submit new report'
    },
    Illness: {
        id: 'header.Illness',
        defaultMessage: 'Illness and Injury report'
    },
    Exposure: {
        id: 'header.Exposure',
        defaultMessage: 'Exposure'
    },
    Fatality: {
        id: 'header.Fatality',
        defaultMessage: 'Fatality'
    },
    Resources: {
        id: 'header.Resources',
        defaultMessage: 'Resources'
    },
    FAQ: {
        id: 'header.FAQ',
        defaultMessage: 'Frequently asked questions'
    },
    HelpfulLink: {
        id: 'header.HelpfulLink',
        defaultMessage: 'Helpful links'
    },
    MSG667: {
        id: 'header.MSG667',
        defaultMessage: 'Are you sure? Are you sure you want to exit? Your report will be deleted.'
    },
    MSG: {
        id: 'header.MSG',
        defaultMessage: 'Your report will be deleted.',
    },
    yes: {
        id: 'header.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'header.no',
        defaultMessage: 'No'
    },
    MSG669: {
        id: 'header.MSG669',
        defaultMessage: 'Are you sure?\nAre you sure you want to change the language?All your progress will be lost'
    },
    MSG668: {
        id: 'header.MSG668',
        defaultMessage: 'Are you sure you want to leave this page?\nAll your progress will be lost'
    },
    stay: {
        id: 'header.stay',
        defaultMessage: 'Stay on this page'
    },
    leave: {
        id: 'header.leave',
        defaultMessage: 'Leave this page'
    },
    wsibLogoTitle: {
        id: 'header.wsibLogoTitle',
        defaultMessage: 'Home. Opens in a new tab.'
    },

});
