/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { getProxiedServiceUrl } from 'gw-portals-url-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonRPCService } from 'gw-portals-transport-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DocumentUploadService } from 'gw-portals-document-js';
// eslint-disable-next-line no-warning-comments
// US-OSBC-215 <exposed the methods to fetch account-claim releated informations for specific user>
import UtilService from './UtilService';

export default class OSBCClaimService {
    static getAuthHeader() {
        let accessToken = document
            .getElementsByTagName('html')[0]
            .getAttribute('authheader');
        accessToken = accessToken
            ? {
                Authorization: `Bearer ${accessToken}`,
                'ocp-apim-subscription-key': UtilService.getSubscriptionKey()
            }
            : undefined;
        return accessToken;
    }

    static getClaimSummariesForEmployerWSIB(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getClaimSummariesForEmployer_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getClaimDetails(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getEmployerClaimDetail_WSIB',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} claimNumber
     * @param {string} employeeLastName
     * @param {object} additionalHeaders
     * @returns claim Details
     * fetch claim details
     */
    static getSearchClaimDetails(param, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getSearchClaimDetail_WSIB',
            [param],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getPaymentDetails(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getEmployerClaimBenefitPayments_WSIB',
            [params],
            this.getAuthHeader()
        );
    }

    /*
    * @param {string} params
    * @param {object} authheader
    * @returns generateToken for document upload
    */

    static claimsDocUploadToken(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'generateUploadToken',
            params,
            this.getAuthHeader()
        );
    }

    /*
    * @param {file} file
    * @param {object} documentMetadata
    * @param {object} additionalHeaders
    * @returns submit document
    */
    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(
            getProxiedServiceUrl('claimDocumentUpload'),
            file,
            documentMetadata,
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getHealthCareDetails(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getEmployerClaimHealthCareBenefits_WSIB',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getAppealsDetails(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getClaimAppeals_WSIB',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getMessageList(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getEmployerMessagesForCustomer',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * save message
     */
    static saveMessage(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'saveMessage',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * message count
     */
    static messageCount(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getNewMessageCountForEmployer',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * get Message Thread
     */
    static getMessageThread(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getMessageThread',
            params,
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * get Message Thread
     */
    static getTombstoneData(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getTombstoneDetail_WSIB',
            [params],
            this.getAuthHeader()
        );
    }

    static getAccountFilterData(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('claim'),
            'getAccountFilterList_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    static getActiveClaims(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getActiveClaimSummaries_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    static getFilterClaims(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getClaimAdvanceSearchSummary_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    static getArchiveClaims(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getArchiveClaimSummaries_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    static getFilterClaimsClaimNo(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getClaimSummariesByClaimNumber_WSIB',
            params,
            this.getAuthHeader()
        );
    }
}
