/* eslint-disable no-unused-vars */
import { start } from '@jutro/app';
/* import {
    initDefaultGA, initDefaultMixpanel, loadGA, subscribe, publish
} from '@jutro/events'; */
import {
    initDefaultGA, initDefaultMixpanel, loadGA, subscribe, publish
} from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import App from './app/App';
import myCustomThemeConfig from './themes/customTheme/config';

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

let cloudAuthConfig = {};
if (authentication.authServer === 'okta') {
    cloudAuthConfig = authentication.servers.okta;
}

// Initialize Google Analytics connection using tracking ID
// const ga = loadGA('UA-183978625-1');
const ga = loadGA('G-T3LCZYM10T');
// anonymizeIp set true
ga('set', 'anonymizeIp', true);
// setting siteSpeedSampleRate to 10 ---- Requirement change
ga('set', 'siteSpeedSampleRate', 10);

// Custom topic subscription (Page View)
/* subscribe(
    ['Page View'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale // return published locale as the event label
        });
    }
); */


subscribe(
    ['Page View'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

// Custom topic subscription (Button Click)
/* subscribe(
    ['Button Click'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale // return published locale as the event label
        });
    }
); */

subscribe(
    ['Button Click'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);


// Custom topic subscription (Exit Page View)
/* subscribe(
    ['Exit Page View'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale // return published locale as the event label
        });
    }
);*/

subscribe(
    ['Exit Page View'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

// Custom topic subscription (Link Click)
/* subscribe(
    ['Link Click'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale // return published locale as the event label
        });
    }
);*/

subscribe(
    ['Link Click'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/claims'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/claims'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/view-claim-list-home'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/view-claim-list-home'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/claimOverview'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/claimOverview'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/benefitPayment'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/benefitPayment'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/healthCare'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/healthCare'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/documentUpload'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/documentUpload'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/sendmessage'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/sendmessage'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/mail'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/mail'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);


/* subscribe(
    ['/account-management/mailDetail'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/mailDetail'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

/* subscribe(
    ['/account-management/helpful-link'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/helpful-link'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);


/* subscribe(
    ['/account-management/faq'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['/account-management/faq'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);
subscribe(
    ['/account-management/appeals'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);
/* subscribe(
    ['timing_complete'],
    (event, topic) => {
        ga('send', { // send to Google Analytics
            hitType: 'event',
            eventAction: event.eventCategory,
            eventCategory: topic, // return published eventCategory
            eventLabel: event.locale, // return published locale as the event label,
            timeOnPage: event.eventCategory
        });
    }
);*/

subscribe(
    ['timing_complete'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);
subscribe(
    ['Error'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

subscribe(
    ['Warning'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);
subscribe(
    ['AverageTimeSpentPage'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

subscribe(
    ['Bounce'],
    (event, topic) => {
        window.dataLayer.push({
            event: event.eventCategory,
            eventCategory: topic,
            eventLabel: event.locale
        });
    }
);

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    config: [cloudAuthConfig],
    refreshOnLocaleChange: true,
    routerBasename: '/account-management',
    onInit: () => {
        LocaleService.register();
    },
    // eslint-disable-next-line no-warning-comments
    appDescription: 'Description', // ToDo
    // eslint-disable-next-line no-warning-comments
    modalProvider: 'default', // ToDo
    themeConfig: myCustomThemeConfig
});

// eslint-disable-next-line import/prefer-default-export
export { default as SessionTimeoutService } from './wsib/services/SessionTimeoutService';
