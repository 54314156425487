import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionExpiredHeading: {
        id: 'session-expired.Session-expiry-label',
        defaultMessage: 'Your session has expired.'
    },
    loginAgainLink: {
        id: 'session-expired.Login-again-link',
        defaultMessage: 'Log in again'
    },

});