import {
    useEffect, useContext, useCallback
} from 'react';
import {
    renderContentFromMetadata,
    validateMetadata,
} from '@jutro/uiconfig';
// for Getting started
import { TranslatorContext, LocaleService } from '@jutro/locale';
import messages from './session-expired.messages';
import styles from './session-expired.module.scss';
import uiMetadata from './session-expired.metadata.json5';
import UtilService from '../../services/UtilService';

function SessionExpired() {
    // eslint-disable-next-line no-unused-vars
    const translator = useContext(TranslatorContext);
    const getBusinessLink = useCallback(
        () => {
            return UtilService.businessLink(LocaleService.getStoredLanguage());
        },
        []
    );

    useEffect(() => {
        validateMetadata(uiMetadata);
    }, []);

    const override = {
        sessionExpiredHeading: {
            content: translator(messages.sessionExpiredHeading),
        },
        loginAgainLink: {
            href: getBusinessLink('pageBusinessHome')
        },

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };
    return renderContentFromMetadata(uiMetadata.sessionExpired, override, resolvers);
}

export default SessionExpired;
