import { defineMessages } from 'react-intl';

export default defineMessages({
    heading: {
        id: 'reply-message.heading',
        defaultMessage: 'Reply to a message'
    },
    para1: {
        id: 'reply-message.para1',
        defaultMessage: 'All information you share will be added to the claim file. Please see our'
    },
    para2: {
        id: 'reply-message.para3',
        defaultMessage: 'for more information.'
    },
    FAQLink: {
        id: 'reply-message.FAQLink',
        defaultMessage: 'frequently asked questions'
    },
    Subject: {
        id: 'reply-message.Subject',
        defaultMessage: 'Subject:'
    },
    Body: {
        id: 'reply-message.Body',
        defaultMessage: 'Body:'
    },
    Send: {
        id: 'reply-message.Send',
        defaultMessage: 'Send message'
    },
    contactUsText: {
        id: 'reply-message.contactUsText',
        defaultMessage: 'We will respond to messages within two business days. If you have an immediate request or any other questions, please'
    },
    contactUsLink: {
        id: 'reply-message.contactUsLink',
        defaultMessage: 'contact us'
    },
    ChooseOne: {
        id: 'reply-message.Chooseone',
        defaultMessage: 'Choose one'
    },
    MSG41: {
        id: 'reply-message.MSG41',
        defaultMessage: 'Please try again later. If you’re still having trouble with this page, call us at: 1-800-387-0750.'
    },
    MSG22: {
        id: 'reply-message.MSG22',
        defaultMessage: 'Are you sure you want to leave this page?\nYou will lose the information you entered.'
    },
    StayOnPage: {
        id: 'reply-message.StayOnPage',
        defaultMessage: 'Stay on this page'
    },
    LeavePage: {
        id: 'reply-message.LeavePage',
        defaultMessage: 'Leave this page'
    },
    MSG79: {
        id: 'reply-message.MSG79',
        defaultMessage: 'Are you sure you want to continue?\nYou will lose the information you entered'
    },
    Okay: {
        id: 'reply-message.Okay',
        defaultMessage: 'Okay'
    },
    Cancel: {
        id: 'reply-message.Cancel',
        defaultMessage: 'Cancel'
    },
});
