import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import {
    ModalNextProvider,
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import messages from './LogoutWarning.messages';

function InactiveConfirmModal(props) {
    const {
        isOpen,
        onResolve,
        logoutConfirmationInterval
    } = props;

    const translator = useContext(TranslatorContext);

    useEffect(() => {
        const closeModal = setTimeout(onResolve, logoutConfirmationInterval);
        return () => window.clearTimeout(closeModal);
    }, [logoutConfirmationInterval, onResolve]);

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader
                title={messages.sessionTimeout}
                status="warning"
                icon="mi-error-outline"
            />
            <ModalBody>
                {translator(messages.warningAboutToExpire)}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onResolve}>
                    {translator(messages.continueSession)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

InactiveConfirmModal.propTypes = {
    isOpen: PropTypes.string.isRequired,
    logoutConfirmationInterval: PropTypes.number.isRequired,
    onResolve: PropTypes.func.isRequired
};

function LogoutWarningModal(resetInactivityTimer, logoutConfirmationInterval) {
    ModalNextProvider.showModal(
        <InactiveConfirmModal
            logoutConfirmationInterval={logoutConfirmationInterval}
        />
    ).then(() => {
        resetInactivityTimer();
    }, _.noop);
}

export default LogoutWarningModal;
