import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import SessionTimeoutModal from '../common/session-timeout-modal/session-timeout-modal';
import SessionTimeout from '../common/session-timeout-modal/SessionTimeout';
import UtilService from './UtilService';

const { sessionConfig, authentication: authConfig } = config;

function getConfig() {
    if (_.isNil(authConfig)) {
        return undefined;
    }
    const serverConfig = _.get(authConfig, `servers.${authConfig.authServer}`);
    return { ...authConfig, ...serverConfig };
}
function SessionTimeoutService() {
    // TODO: pass inactivityIntervalMins,logoutConfirmationIntervalMins parameters from config
    const oAuthConfig = getConfig();
    return SessionTimeout({
        oAuthConfig,
        logoutWarningCallback: SessionTimeoutModal,
        inactivityIntervalMins: UtilService.getSessionTimeoutValue(),
        inactivityIntervalMins: 14,
        logoutConfirmationIntervalMins: 1
    });
}
export default SessionTimeoutService;
